<template>
  <v-container>
    <v-row class="mt-2">
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(discount, index) in activeCopuns"
        :key="'active-discount-' + index"
        class="coupon-col"
      >
        <CouponCard
          :coupon="discount"
          @showDetails="showActiveCoupon"
          @reload="getActiveCopuns"
          :type="'activeDiscount'"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(discount, index) in vouchers"
        :key="'voucher-' + index"
      >
        <CouponCard
          :coupon="discount"
          @showDetails="showVoucher"
          @reload="loadVouchers"
          :type="'voucher'"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(coupon, index) in galleryCoupons"
        :key="'coupon-' + index"
        ><ProductCard
          :product="coupon"
          :type="
            coupon.metaData.voucher_info.selected ? 'selected' : 'available'
          "
          @openDetails="openDetails"
          @reload="getSelectedCoupons"
      /></v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(discount, index) in activeCashbackCoupons"
        :key="'active-cashback-discount-' + index"
        class="coupon-col"
      >
        <WelcomeCouponCard
          :coupon="discount"
          @showDetails="showActiveCoupon"
          @reload="getActiveCashbackCoupons"
          :type="'activeCashbackDiscount'"
          class="welcome-style"
          :customCssClass="'welcome-style'"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(discount, index) in activeWelcomeCoupons"
        :key="'active-welcome-discount-' + index"
        class="coupon-col"
      >
        <CouponCard
          :coupon="discount"
          @showDetails="showActiveCoupon"
          @reload="getActiveCopuns"
          :title="'coupons.discounts.welcome'"
          :type="'welcomeCoupon'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CouponsService from "@/service/couponsService";
import CouponCard from "@/components/coupon/CouponCard.vue";
import WelcomeCouponCard from "@/components/coupon/WelcomeCouponCard.vue";
import ActiveCouponDetail from "@/components/coupon/ActiveCouponDetail.vue";
import VoucherDetail from "@/components/coupon/VoucherDetail.vue";
import GaleryCouponDetail from "@/components/coupon/GaleryCouponDetail.vue";
import ProductCard from "@/components/product/ProductCard.vue";

export default {
  name: "CouponActive",
  components: { CouponCard, ProductCard, WelcomeCouponCard },
  data() {
    return {
      activeCopuns: [],
      vouchers: [],
      galleryCoupons: [],
      activeWelcomeCoupons: [],
      activeCashbackCoupons: []
    };
  },
  methods: {
    async reload() {
      this.getActiveCopuns();
      this.loadVouchers();
      this.getSelectedCoupons();
      this.getActiveCashbackCoupons();
      this.getActiveWelcomeCoupons();
    },
    async getActiveCopuns() {
      CouponsService.getActiveCopuns().then(data => {
        this.activeCopuns = data.data.values;
      });
    },
    async loadVouchers() {
      CouponsService.getVouchers().then(data => {
        this.vouchers = data.giftCertificates;
      });
    },
    async getSelectedCoupons() {
      CouponsService.getCouponGallery(true).then(data => {
        this.galleryCoupons = data.giftCertificates;
      });
    },
    async getActiveCashbackCoupons() {
      CouponsService.getActivatedDiscountsByType("cashback").then(data => {
        this.activeCashbackCoupons = data.values;
      });
    },
    async getActiveWelcomeCoupons() {
      CouponsService.getActivatedDiscountsByType("welcome").then(data => {
        this.activeWelcomeCoupons = data.values;
      });
    },
    async showActiveCoupon(coupon, type, style = "") {
      let res = await this.$dialog.show(ActiveCouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: coupon,
        type: type,
        customClass: style,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    },
    async showVoucher(voucher) {
      let res = await this.$dialog.show(VoucherDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: voucher,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    },
    async openDetails(item) {
      let res = await this.$dialog.show(GaleryCouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: item,
        type: item.metaData.voucher_info.selected ? "selected" : "available",
        width: 500
      });
      if (res) {
        this.loadCoupons();
      }
    }
  },
  async created() {
    await this.reload();
  }
};
</script>
